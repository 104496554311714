import Vue from 'vue'
import { baseUrl } from '../endpoints'

const timeout = 50000

export default {
  updateProfile (data) {
    return Vue.axios.post(baseUrl + 'agency/profile/update', data, {
      timeout: timeout
    })
  },

  getActiveTrips () {
    return Vue.axios.get(baseUrl + 'agency/trips/active', { timeout: timeout })
  },

  getInactiveTrips () {
    return Vue.axios.get(baseUrl + 'agency/trips/inActive', { timeout: timeout })
  },

  addTrip (data) {
    return Vue.axios.post(baseUrl + 'agency/trips', data, {
      timeout: timeout
    })
  },

  updateTrip (id, trip) {
    return Vue.axios.post(baseUrl + 'agency/trips/' + id, trip, {
      timeout: timeout
    })
  },

  addImage (tripId, image) {
    return Vue.axios.post(baseUrl + 'agency/trips/' + tripId + '/images', image, {
      timeout: timeout
    })
  },

  deleteImage (tripId, imageId) {
    return Vue.axios.delete(baseUrl + 'agency/trips/' + tripId + '/images/' + imageId, {
      timeout: timeout
    })
  },

  getBookings () {
    return Vue.axios.get(baseUrl + 'agency/trips/bookings', { timeout: timeout })
  },

  getBookingDetails (id) {
    return Vue.axios.get(baseUrl + 'agency/trips/bookings/' + id, { timeout: timeout })
  },

  confirmBooking (id) {
    return Vue.axios.patch(baseUrl + 'agency/trips/bookings/' + id + '/confirm', {}, { timeout: timeout })
  }
}
