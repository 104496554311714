<template>
    <v-container>
        <div class="d-flex flex-wrap justify-space-between align-start">
            <div :style="$vuetify.breakpoint.mdAndUp ? 'width: 50%' : 'width: 100%'">
                <h1>{{agencyTrip.name}}</h1>
                <h2 class="paragraph--text" v-if="agencyTrip.city"><v-icon>mdi-map-marker</v-icon>{{agencyTrip.city}}</h2>
            </div>
            <div>
                <v-btn v-if="$route.path.includes('/vendors')" link :to="{name: 'addAgencyTrip', params: {action: 'edit'}}" rounded outlined color="primary" class="px-7 my-2">Edit trip</v-btn>
                <v-btn v-if="$route.path.includes('/vendors')" link :to="{name: 'addAgencyTrip', params: {action: 'add'}}" rounded color="primary" elevation="0" class="px-7 ml-2">add new trip</v-btn>
                <div class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? 'justify-end' : 'justify-start'">
                    <div>
                        <v-switch
                        class="mr-auto"
                        @change="updateStatus"
                        inset
                        v-model="agencyTrip.is_active"
                        :color="agencyTrip.is_active ? 'success' : 'error'"
                        label="Active TRIP"
                        v-if="$route.path.includes('/vendors')"
                        ></v-switch>
                        <div class="">
                            <v-rating
                            color="warning"
                            dense
                            full-icon="mdi-star"
                            length="3"
                            readonly
                            size="28"
                            :value="3"
                            ></v-rating>
                        </div>
                        <h2 class=" primary--text">{{agencyTrip.price_per_person | currency}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <v-row no-gutters class="my-10">
            <v-col cols="12" md="8" class="pr-5 mb-5">
                <v-img class="rounded-xl" max-height="300" :src="agencyTrip.image"></v-img>
                <v-row no-gutters>
                    <template v-for="(item, i) in agencyTrip.images">
                      <v-col v-if="i < 4" class="mt-2 pa-1" :key="i" cols="12" sm="6" md="3">
                        <v-img :gradient="i === 3 ? 'to top right, rgb(239 25 183 / 70%), rgba(239 25 183 / 70%)' : ''"
                        class="d-flex align-center justify-center pa-0 rounded-xl"
                        :src="item.image_name"
                        height="100"
                        >
                            <v-btn @click="imagesDilaog = true" v-if="i === 3" text color="white" class="font-weight-bold" x-large>+ {{agencyTrip.images.length - 3}}</v-btn>
                        </v-img>
                      </v-col>
                    </template>
                </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="rounded-xl">
                    <v-card-title class="text-center primary--text"><div class="mx-auto">Trip Tags</div></v-card-title>
                    <v-card-text>
                        <div v-if="agencyTrip.tags.length">
                            <v-chip color="#F5F5F5" v-for="(item, i) in agencyTrip.tags" :key="i" class="ma-1 d-lock">
                                {{item.name}}
                                <br>
                                <v-rating
                                background-color="primary lighten-1"
                                color="primary"
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                length="5"
                                readonly
                                size="15"
                                dense
                                :value="item.rank ? item.rank : 1"
                                ></v-rating>
                            </v-chip>
                        </div>
                        <p v-else class="primary--text text-center">No tags provided!</p>
                    </v-card-text>
                </v-card>

                <v-card class="rounded-xl pa-3 mt-5">
                    <v-card-title class="body-1 font-weight-bold">Trip Location</v-card-title>
                    <h4 class="paragraph--text" v-if="agencyTrip.city"><v-icon>mdi-map-marker</v-icon>{{agencyTrip.city}}</h4>
                    <GmapMap
                    :center='{lat: agencyTrip.lat || 0, lng: agencyTrip.lng || 0}'
                    ref="gmap"
                    :zoom='8'
                    style='width:100%;  height: 200px;'
                    >
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in [{position: {lat: agencyTrip.lat || 0, lng: agencyTrip.lng || 0}}]"
                        :position="m.position"
                      />
                    </GmapMap>
                </v-card>
            </v-col>
        </v-row>

        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-title class="primary--text text-h5 text-capitalize">trip name</v-card-title>
            <v-card-text class="text-h5">
                {{agencyTrip.name}}
            </v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-title class="primary--text text-h5 text-capitalize">recommended date</v-card-title>
            <v-card-text class="text-h5"><v-icon>mdi-calendar</v-icon> {{agencyTrip.date}}</v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-title class="primary--text text-h5 text-capitalize">description</v-card-title>
            <v-card-text class="text-h5">{{agencyTrip.description}}</v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-title class="primary--text text-h5 text-capitalize">Trip Itinerary</v-card-title>
            <v-card-text class="text-h5">{{agencyTrip.itinerary}}</v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-text class="d-flex justify-space-between flex-wrap">
                <div>
                    <h2 class="primary--text mb-3">No. of days</h2>
                    <h2 class="text-center">{{agencyTrip.days}}</h2>
                </div>
                <div>
                    <h2 class="primary--text mb-3">No. of nights</h2>
                    <h2 class="text-center">{{agencyTrip.nights}}</h2>
                </div>
                <div>
                    <h2 class="primary--text mb-3">trip occupancy</h2>
                    <h2 class="text-center">{{agencyTrip.occupancy}}</h2>
                </div>
            </v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-text class="d-flex justify-space-between flex-wrap">
                <div>
                    <h2 class="primary--text mb-3">price/person</h2>
                    <h2 class="text-center">{{agencyTrip.price_per_person | currency}}</h2>
                </div>
                <div>
                    <h2 class="primary--text mb-3">solo price</h2>
                    <h2 class="text-center">{{agencyTrip.solo_price | currency}}</h2>
                </div>
                <div>
                    <h2 class="primary--text mb-3">lemo price</h2>
                    <h2 class="text-center">{{agencyTrip.limo_price | currency}}</h2>
                </div>
            </v-card-text>
        </v-card>
        <v-card outlined class="pa-5 rounded-xl my-5">
            <v-card-title class="primary--text text-h5 text-capitalize">payment links</v-card-title>
            <v-card-text>
                <h3 v-for="link in agencyTrip.payment_link" :key="link">{{link}}</h3>
            </v-card-text>
        </v-card>

        <v-dialog max-width="80%" v-model="imagesDilaog">
            <v-card class="pa-5">
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="item in agencyTrip.images" :key="item.id">
                        <v-img
                        class="rounded-xl"
                        :src="item.image_name">
                        </v-img>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import vendors from '@/web_services/vendors'

export default {
  data () {
    return {
      imagesDilaog: false
    }
  },
  computed: {
    ...mapState(['agencyTrip'])
  },
  methods: {
    updateStatus () {
      // const formData = new FormData()
      // formData.append('_method', 'PUT')
      // formData.append('name', trip.name)
      // formData.append('date', trip.date)
      // formData.append('description', trip.description)
      // formData.append('itinerary', trip.itinerary)
      // formData.append('days', trip.days || 2)
      // formData.append('nights', trip.nights)
      // formData.append('occupancy', trip.occupancy)
      // formData.append('price_per_person', trip.price_per_person)
      // formData.append('solo_price', trip.solo_price || '')
      // formData.append('limo_price', trip.limo_price || '')
      // formData.append('city_id', trip.city.id)
      // formData.append('is_active', trip.is_active ? 1 : 0)
      // trip.payment_links.forEach((item, i) => {
      //   formData.append(`payment_links[${i}]`, item)
      // })
      // trip.tags.forEach((item, i) => {
      //   formData.append(`tags[${i}]`, item.id)
      // })
      // formData.append('image', this.image, this.image.name)
      // this.images.forEach((item, i) => {
      //   formData.append(`images[${i}]`, item, item.name)
      // })
      const body = {
        name: this.agencyTrip.name,
        description: this.agencyTrip.description,
        itinerary: this.agencyTrip.itinerary,
        date: this.agencyTrip.date,
        days: this.agencyTrip.days || 2,
        nights: this.agencyTrip.nights,
        occupancy: this.agencyTrip.occupancy,
        price_per_person: this.agencyTrip.price_per_person,
        solo_price: this.agencyTrip.solo_price || '',
        limo_price: this.agencyTrip.limo_price || '',
        city: this.agencyTrip.city,
        lat: this.agencyTrip.lat,
        lng: this.agencyTrip.lng,
        payment_links: this.agencyTrip.links,
        is_active: this.agencyTrip.is_active ? 1 : 0,
        tags: []
      }
      this.agencyTrip.tags.map(tag => body.tags.push(tag.id))
      vendors.updateTrip(this.agencyTrip.id, body).then(res => {}).catch(() => {})
    }
  },
  created () {
    if (!this.agencyTrip.id) this.$router.go(-1)
  }
}
</script>
